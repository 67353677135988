.theme-Midnight .notepad-explorer a {
	color: #ddd;
}

.theme-Midnight .btn-flat {
	color: #ddd;
}

.theme-Midnight .btn:hover, .theme-Midnight .btn-large:hover {
	background-color: #424242;
}
