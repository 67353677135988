.theme-Pastel a {
	color: #333;
}

.theme-Pastel .notepad-explorer a {
	color: #231e23;
}

#breadcrumb-holder > nav.Pastel {
	background-color: #e0ece4;
}
