/* Thanks to https://gist.github.com/spemer/a0e218bbb45433bd611e68446523a00b */

@media (min-device-width: 600px) {
	/* total width */
	::-webkit-scrollbar {
		background-color: transparent;
		width: 7px;
		height: 7px;
	}

	/* background of the scrollbar except button or resizer */
	::-webkit-scrollbar-track {
		background-color: transparent;
	}

	/* scrollbar itself */
	::-webkit-scrollbar-thumb {
		background-color: #babac0;
		border-radius: 7px;
	}

	/* set button(top and bottom of the scrollbar) */
	::-webkit-scrollbar-button {
		display: none;
	}

	::-webkit-scrollbar-corner {
		background-color: transparent;
		border-color: transparent;
	}
}
