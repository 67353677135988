.notepad-explorer {
	order: 0;
	width: 100%;
	overflow: auto;
	overflow-wrap: break-word;
	white-space: normal;

	padding: 5px 5px 5px 10px;

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 10;
}

.notepad-explorer a {
	color: var(--mp-theme-explorerContent);
}

.notepad-explorer .due-date-list {
	margin-bottom: 20px;
	display: inline;
}

.notepad-explorer .tree-view {
	width: 100%;
}

.notepad-explorer .tree-view_children {
	padding-left: 16px;
}

.notepad-explorer .tree-view_children-collapsed {
	height: 0;
}

.notepad-explorer .tree-view_arrow {
	cursor: pointer;
	margin-right: 6px;
	display: inline-block;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.notepad-explorer .tree-view_arrow:after {
	content: '▾';
}

.notepad-explorer .tree-view_arrow-collapsed {
	-webkit-transform: rotate(-90deg);
	-moz-transform: rotate(-90deg);
	-ms-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.notepad-explorer .tree-view_item > span > a {
	padding-left: 5px;
}

.notepad-explorer .tree-view_item > span > div, .explorer-note > span > div {
	padding-left: 5px;
	display: inline-block;
}

.explorer-note {
	display: flex;
	width: 100%;
	white-space: nowrap;
	padding-left: 16px;
	padding-bottom: 10px;
	padding-top: 10px;
}

.explorer-note > span > .material-icons {
	padding-right: 5px;
}

.add-button {
	padding: 0;
}

.add-button > button, .notepad-explorer button {
	color: var(--mp-theme-explorerContent);
	padding-left: 3px;
	padding-right: 3px;
	margin-right: 3px;
}

.add-button > button > .material-icons {
	vertical-align: bottom;
}

.explorer-note > span > a:nth-child(2) {
	padding-left: 5px;
	display: inline-flex;
}

.notepad-explorer > div > strong > a {
	padding-left: 5px;
}

.explorer-options-modal button {
	width: 260px;
}

.explorer-options-modal .col > button {
	width: initial;
}

.tree-view {
	width: -moz-max-content;
	width: max-content;
}

.app-settings-component__action-list {
	margin-top: 5px;
}

.app-settings-component__action-list > li {
	padding-left: 1em;
}

.due-date-list > ol {
	margin-top: 5px;
}

.sync-settings-component__action-list > li {
	padding-left: 1em;
}

.sync-settings-component__action-list > li > a {
	text-decoration: underline;
}

.notepad-explorer .tree-view_item {
	width: 100%;
	white-space: nowrap;
}
