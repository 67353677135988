#note-viewer {
	order: 1;
	width: 100%;
	overflow: auto;

	background-position: center center, left top;
	background-repeat: no-repeat, repeat;
}

#note-container {
	transform-origin: 0 0;
	transform: scale(1);
}

#progress-bar {
	position: relative;
}

#progress-bar > .progress {
	background-color: var(--mp-theme-chrome);
	position: absolute;
}

#progress-bar > .progress .indeterminate {
	background-color: var(--mp-theme-accent);
}
