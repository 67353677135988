:root {
	--mp-mobile-width: 900px;
}

body {
	margin: 0;
	padding: 0;

	cursor: default;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;

	font-family: "ABeeZee", "Roboto", sans-serif;

	font-size: 14px;
}

#body {
	position: absolute;
	display: flex;
	width: 100vw;
}

a {
	-webkit-user-drag: none;
}

nav {
	z-index: 1000 !important;
	cursor: default;
	background-color: var(--mp-theme-chrome);

	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

#mobile-nav {
	z-index: 1000;
}

.side-nav li {
	color: rgba(0,0,0,0.87);
}

.dropdown-content {
	overflow: auto !important;
}

.dropdown-content a {
	color: black !important;
}

nav .material-icons.left {
	margin-right: 10px;
}

nav .menu-items > div {
	float: left;
	padding: 0;
}

.material-icons {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.vex .vex-content {
	font-family: "ABeeZee", "Roboto", sans-serif !important;
}

.collection a.collection-item {
	color: #607d8b;
}

.dropdown-content li>span {
	color: #607d8b;
}

/* Materialize modals need to always be on top. MicroPad doesn't go over 5000 for anything native. */
.modal.open {
	z-index: 5002 !important;
}
.modal-overlay {
	z-index: 5001 !important;
}
.vex, .vex-overlay {
	z-index: 5003 !important;
}

.vex-dialog-prompt-input {
	padding: 0 !important;
}

div.col.input-field {
	width: 100%;
}

.react-resizable {
	position: relative;
}
.react-resizable-handle {
	position: absolute;
	width: 20px;
	height: 20px;
	bottom: 0;
	right: 0;
	padding: 0 3px 3px 0;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+') no-repeat bottom right;
	background-origin: content-box;
	box-sizing: border-box;
	cursor: se-resize;
}

input[type=date]::-webkit-inner-spin-button, input[type=time]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	display: none;
}

button, input[type=button] {
	font-family: "ABeeZee", "Roboto", sans-serif;
}

.noteElement input {
	color: var(--mp-theme-text);
}

strong {
	font-weight: bolder;
}

.modal-content > h4:first-of-type {
	word-break: break-word;
}

.header__top-level-item .material-icons {
	height: var(--mp-nav-height) !important;
	line-height: var(--mp-nav-height) !important;
}

.accent-btn {
	background-color: var(--mp-theme-accent);
	color: var(--mp-theme-accentContent);
}

.chrome-btn {
	background-color: var(--mp-theme-chrome);
	color: var(--mp-theme-explorerContent);
}

.col label {
	color: var(--mp-theme-text);
	opacity: 0.7;
}

/* The background of the checked box */
#app [type=checkbox].filled-in:checked+span:not(.lever):after, .vex [type=checkbox].filled-in:checked+span:not(.lever):after {
	background-color: var(--mp-theme-accent);
	border: 2px solid #5a5a5a;
}

/* The tick */
#app [type=checkbox].filled-in:checked+span:not(.lever):before, .vex [type=checkbox].filled-in:checked+span:not(.lever):before {
	border-right: 2px solid var(--mp-theme-accentContent);
	border-bottom: 2px solid var(--mp-theme-accentContent);
}

/* Same as above but for modals */
.modal [type=checkbox].filled-in:checked+span:not(.lever):after, .vex [type=checkbox].filled-in:checked+span:not(.lever):after {
	background-color: var(--mp-theme-accent);
	border: 2px solid #5a5a5a;
}
.modal [type=checkbox].filled-in:checked+span:not(.lever):before, .vex [type=checkbox].filled-in:checked+span:not(.lever):before {
	border-right: 2px solid var(--mp-theme-accentContent);
	border-bottom: 2px solid var(--mp-theme-accentContent);
}

.btn:hover, .btn-flat:hover, .btn-large:hover, .btn-small:hover {
	background-color: var(--mp-theme-accent);
	color: var(--mp-theme-accentContent);
}

.red.btn, .red.btn-large, .red.btn-small {
	color: #fff !important;
}

.btn:focus, .btn-large:focus, .btn-small:focus, .btn-floating:focus {
	background-color: var(--mp-theme-accent);
	color: var(--mp-theme-accentContent);
}

.danger.btn:hover, .danger.btn-flat:hover, .danger.btn-large:hover, .danger.btn-small:hover {
	background-color: #F44336;
	color: #fff;
}

::placeholder {
	color: var(--mp-theme-text);
	opacity: 0.5;
}
