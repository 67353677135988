.markdown-element__options-input {
	color: var(--mp-theme-text);
}

.markdown-element__code-editor {
	resize: both;
	overflow: hidden;
}

.markdown-element__code-editor > * {
	min-height: 400px;
}

#markdown-editor-label {
	display: block;
}

#markdown-editor-label > div {
	display: inline;
}
