#insert-element {
	width: max-content;
	width: -moz-max-content;
	background-color: white;
	padding: 10px;
	position: absolute;
}

#insert-element ul > li {
	margin-bottom: 10px;
}